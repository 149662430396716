import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const styles = {
  statusIndicator: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 1,
  },
  operational: {
    backgroundColor: '#4caf50',
  },
  degraded: {
    backgroundColor: '#ff9800',
  },
  outage: {
    backgroundColor: '#f44336',
  },
  timestamp: {
    color: 'text.secondary',
    fontSize: 'small',
    display: 'block',
  }
}

export default function CloudUrlStatus({url}) {
  const [urlStatus, setUrlStatus] = useState(null);
  const [urlStatusCheckedAt, setUrlStatusCheckedAt] = useState(null);
  
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'operational':
        return styles.operational;
      case 'degraded':
        return styles.degraded;
      case 'outage':
        return styles.outage;
      default:
        return styles.operational;
    }
  };

  useEffect(() => {
    const checkUrlStatus = async () => {
      try {
        await fetch(url);
        setUrlStatus('Available');
      } catch (error) {
        setUrlStatus('Degraded');
      } finally {
        setUrlStatusCheckedAt(new Date().toLocaleString());
      }
    };

    checkUrlStatus();
  }, [url]);

  return (
    <Box>
      <Typography>
        <Typography component="h6" color="text.secondary">
          {url.replace("https://", "")}
        </Typography>
        <Box
          component="span"
          sx={{ ...styles.statusIndicator, ...getStatusColor(urlStatus) }}
        />
        {' '}
        {urlStatus}
        {' '}
        <Typography component="span" sx={styles.timestamp}>
          (last ping: {urlStatusCheckedAt})
        </Typography>
      </Typography>
    </Box>
  )
}
