import React, {useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import RunStatusIndicator from './RunStatusIndicator'

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function RunStatus(props) {
    const {
        id,
        name,
        createdAtAttribute,
        sequencingPlatformAttribute,
        handleClick,
        sequencerNameAttribute,
        sequencerSNAttribute,
        activeRun,
        uuid
    } = props;

    const { getAccessTokenSilently } = useAuth0();

    const [run, setRun] = useState(null);

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                const headers = new Headers({
                    Authorization: `Bearer ${accessToken}`,
                });

                let extraFields = [
                    'status'
                ]

                if (!sequencingPlatformAttribute) {
                    extraFields.push('platform')
                }


                return fetch(
                    `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${id}?extra_fields=${extraFields.join(',')}`,
                    {
                        method: 'GET',
                        headers,
                    },
                )
            })
            .then((res) => res.json())
            .then((response) => {
                setRun(response.data);
            })
            .catch((error) => console.error(error));
    }, [id, getAccessTokenSilently, sequencingPlatformAttribute]);

    let platformLogo = null

    let statusText = '-'
    let createdAtValue = null
    if (sequencingPlatformAttribute || run) {
        platformLogo = (sequencingPlatformAttribute === 'illumina' || run?.attributes.sequencing_platform === 'illumina') ?
            illuminaLogo : ontLogo
    }

    if (createdAtAttribute || run) {
        createdAtValue = new Date(createdAtAttribute || run.attributes.created_at).toLocaleString()
    }

    if (run) {
        statusText = 'Uploading'
        if (run.attributes.status === 'basecalling') {
            statusText = 'Basecalling'
        } else if (run.attributes.status === 'analyzing') {
            statusText = 'Analyzing'
        } else if (run.attributes.status === 'delayed') {
            statusText = 'Delayed'
        } else if (run.attributes.status === 'failed') {
            statusText = 'Has Failures'
        } else if (run.attributes.status === 'succeeded') {
            statusText = 'Succeeded'
        }
    }

    let opacity = 1
    if (activeRun && run && (activeRun.id !== run.id)) {
      opacity = 0.5
    }

    const sequencerNameLabels = {
        p2: "P2",
        p24: "P24",
        miseq: "MiSeq",
        nextseq_500: "NextSeq 500",
        nextseq_550: "NextSeq 550",
        hiseq_1000: "HiSeq 1000",
        hiseq_2000: "HiSeq 2000"
    }

    return (
      <Grid item xs={12} sm={4}>
        <Paper
            elevation={2}
            sx={{ opacity: (sequencingPlatformAttribute || run) ? opacity : 0.5, "&:hover": (sequencingPlatformAttribute || run) ? { boxShadow: 6, cursor: 'pointer' } : null }}
            onClick={() => (sequencingPlatformAttribute || run) ? handleClick(sequencingPlatformAttribute ? { id, attributes: { name, uuid, sequencing_platform: sequencingPlatformAttribute } } : run) : null}
        >
          <Box>
            <Grid container sx={{ borderBottom: 2, borderColor: '#ddd' }}>
                <Grid item xs={10} sx={{ padding: '0px 10px 0px 10px' }}>
                    <p style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}><b>{name}</b></p>
                </Grid>
                <Grid item xs={2} sx={{ padding: '12px 10px 0px 0px', textAlign: 'right' }}>
                    {platformLogo &&
                    (<img src={platformLogo} alt="platform-logo" height="25px" />)}
                </Grid>
            </Grid>
            <Grid container sx={{ padding: '0px 10px 0px 10px' }}>
                <Grid item xs={4} sx={{ padding: '15px' }}>
                    <Box sx={{ m: 1, position: 'relative'  }}>
                      <RunStatusIndicator run={run} />
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <p><b>Status:</b> {statusText}</p>
                    <p style={{color: "gray"}}><b>{sequencerNameLabels[sequencerNameAttribute] || sequencerNameAttribute}</b> {sequencerSNAttribute ? `(${sequencerSNAttribute})` : null}</p>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{ fontSize: '0.75em', padding: '0px 20px', backgroundColor: '#eee' }}
            >
                <Grid item xs={12}>
                    <p><b>Created:</b> {createdAtValue}</p>
                </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    )
}

export default RunStatus;
