import React, { useEffect, useMemo } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import modelPanelReference from '../AMRPredictions/modelPanelReference';
import { kgASTVersions } from '../AMRPredictions/kgASTVersionReference';
import { removePrefixFromAnalysisVersion } from '../Analyses/canonicalizeMetadata';

function resolveKgASTVersionFromAnalysisVersion(analysisVersion) {
    if (analysisVersion < '2024.08.05.a') {
        return "v0.7.5.1"
    }

    if (analysisVersion < '2025.02.13.a') {
        return "v0.7.6.0"
    }

    return "v0.7.7.0"
}

function IdentifiedSpeciesVersionDialog(props) {
    const {
        open,
        setOpen,
        analysis
    } = props;

    const [analysisVersion, setAnalysisVersion] = React.useState(null);
    const [gASTVersion, setGASTVersion] = React.useState(null);
    const [clsiVersion, setCLSIVersion] = React.useState(null);
    const [microhmDBVersion, setMicrohmDBVersion] = React.useState(null);
    const [modelPanel, setModelPanel] = React.useState(null);

    useEffect(() => {
        if (!analysis) {
            return;
        }

        const analysisVersionWithPrefixRemoved = removePrefixFromAnalysisVersion(analysis?.attributes.metadata.minion_pipeline_version);
        const gASTVersion = resolveKgASTVersionFromAnalysisVersion(analysisVersionWithPrefixRemoved);
        const clsiVersion = kgASTVersions[gASTVersion].clsiVersion;
        const microhmDBVersion = kgASTVersions[gASTVersion].microhmDBVersion;
        const modelPanel = modelPanelReference[gASTVersion];
        
        setAnalysisVersion(analysisVersionWithPrefixRemoved);
        setGASTVersion(gASTVersion);
        setCLSIVersion(clsiVersion);
        setMicrohmDBVersion(microhmDBVersion);

        // Avoid mutating the original modelPanel with toSorted
        setModelPanel(modelPanel.toSorted((a, b) => a.organism.localeCompare(b.organism)));
    }, [analysis]);

    const resistanceGenePanel = useMemo(() => [
        "blaIMP",
        "blaKPC",
        "blaOXA-48 family",
        "blaOXA-23 family",
        "blaNDM",
        "blaVIM",
        "mcr-1",
        "blaCTX-M",
        "mecA",
        "mecC",
        "vanA",
        "vanB"
    ], [])

    const dataGridColumns = [
        {
            field: 'organism',
            headerName: 'Organism',
            minWidth: 300,
            flex: 0.75,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Organism</b>
            ),
            renderCell: cell => (
                <b>{cell.value}</b>
            )
        },
        {
            field: 'qualified_antibiotics',
            headerName: 'Qualified',
            minWidth: 300,
            flex: 1,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Qualified</b>
            ),
            valueGetter: value => {
                return value.sort().join(', ')
            }    
        },
        {
            field: 'rnd_antibiotics',
            headerName: 'R&D',
            minWidth: 300,
            flex: 1,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>R&D</b>
            ),
            valueGetter: value => {
                return value.sort().join(', ')
            }
        },
    ]

    return (
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
        scroll="paper"
        >
            <DialogTitle sx={{backgroundColor: "#eee"}}>Analysis Version {analysisVersion}</DialogTitle>
            <DialogContent>
               <Box sx={{padding: "10px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <p><b>Keynome ID: v3.0</b> <span style={{color: "gray"}}>(MicroRefDB v2.2.1)</span></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Keynome <i>g</i>AST: {gASTVersion}</b> <span style={{color: "gray"}}>(MicrohmDB {microhmDBVersion} / {clsiVersion})</span></p>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'grid', '& .runs-table--header': { backgroundColor: "#eee" }}}>
                            <p><b>Keynome <i>g</i>AST Panel</b></p>
                            <p style={{margin: "0 0 20px", color: "gray"}}><small><b>Supporting resistance genes reported:</b>&nbsp;
                            {
                                resistanceGenePanel.sort().join(', ')
                            }
                            </small></p>
                            <DataGrid
                                disableColumnSorting
                                autoHeight
                                columns={dataGridColumns}
                                rows={modelPanel} 
                                getRowHeight={() => 'auto'} 
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 0.5,

                                    },

                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor: "#eee"}}>
                <Button color="primary" sx={{marginRight: "20px"}} onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}


export default IdentifiedSpeciesVersionDialog;
