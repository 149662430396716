import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

function SampleInDatasetListItem(props) {
  const {
    project,
    selectedRun,
    sample,
  } = props;

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { ref, inView } = useInView();
  const { hasAccessReportViruses } = useOutletContext();

  const [processingState, setProcessingState] = useState(null);
  const [activeAnalysis, setActiveAnalysis] = useState(null);
  const [activeAnalysisDetails, setActiveAnalysisDetails] = useState(null);
  const [activeRun, setActiveRun] = useState(null);

  useEffect(() => {
    if (!isAuthenticated || !inView) {
      return;
    }

      if (selectedRun) {
          setActiveRun(selectedRun)
          return;
      }

    getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${sample.attributes.sequencing_run_id}?extra_fields=platform,status`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        setActiveRun(response.data);
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, isAuthenticated, inView, sample, selectedRun]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    if (activeAnalysisDetails) {
      return;
    }

    if (!inView) {
      return;
    }

    getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}?extra_fields=positive_species_calls_ct,status`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        setActiveAnalysisDetails(response.data);

        if (response.data.attributes.status === 'succeeded') {
          setProcessingState('analysis_ready')
        } else if (response.data.attributes.status === 'failed') {
          setProcessingState('analysis_failed')
        } else if (response.data.attributes.status === 'delayed') {
          setProcessingState('analysis_delayed')
        } else {
          setProcessingState('analysis_running');
        }
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, isAuthenticated, activeAnalysisDetails, inView, activeAnalysis]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (activeAnalysis) {
      return;
    }

    if (!inView) {
      return;
    }

    getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sample.id}/analyses?primary_only=True`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          setProcessingState('awaiting_analysis');
          return;
        }

        const activeAnalysis = response.data[0];
        setActiveAnalysis(activeAnalysis);
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, isAuthenticated, inView, activeAnalysis, sample]);

  let statusContent = <CircularProgress size={20} sx={{ mt: 2 }} />;
  let isClickable = false
  if (processingState === 'awaiting_analysis') {
    statusContent = (<p><b style={{ color: '#ff9800' }}>Awaiting analysis</b></p>);
  } else if (processingState === 'analysis_ready') {
    isClickable = true
    statusContent = (<p style={{ color: 'green' }}><b>Results ready</b></p>);
  } else if (processingState === 'analysis_failed') {
    statusContent = (<p style={{ color: 'red' }}><b>Analysis failed</b></p>);
  } else if (processingState === 'analysis_delayed') {
    statusContent = (<p style={{ color: '#ff9800' }}><b>Results delayed</b></p>);
  } else if (processingState === 'analysis_running') {
    statusContent = (<p style={{ color: '#0081ff' }}><b>Analyzing</b></p>);
  }

  let yieldContent = <p>-</p>;
  if (activeAnalysis && activeAnalysis.attributes.raw_total_megabases && activeRun) {
    const isIllumina = activeRun.attributes.sequencing_platform === 'illumina' || activeRun.attributes.platform === 'illumina'
    const yieldValue = isIllumina ? (activeAnalysis.attributes.raw_total_megabases + activeAnalysis.attributes.raw_total_megabases_r2) : activeAnalysis.attributes.raw_total_megabases

    const yieldAsFormattedString = yieldValue.toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    );

    yieldContent = (
      <p>
        {yieldAsFormattedString}
        {' '}
        MBs
      </p>
    );
  }

  let speciesIDContent = <p>-</p>;
  if (activeAnalysisDetails) {
    let speciesIDCall = '-';
    let pluralityOfSpeciesIDCall = null;
    if (processingState === 'analysis_ready') {
        speciesIDCall = (!activeRun || (activeRun.attributes.sequencing_platform === 'illumina' || activeRun.attributes.platform === 'illumina')) ? '-' : 'Negative'

        let positive_species_calls_ct = activeAnalysisDetails.attributes.positive_species_calls_ct
        if (!hasAccessReportViruses && activeAnalysisDetails.attributes?.positive_species_calls_cts_by_domain) {
          positive_species_calls_ct -= activeAnalysisDetails.attributes.positive_species_calls_cts_by_domain['virus'];
        }

        if (positive_species_calls_ct > 0) {
          speciesIDCall = 'Positive';

          pluralityOfSpeciesIDCall = (positive_species_calls_ct === 1)
            ? ' (monomicrobial)' : ' (polymicrobial)';
        }

    }

    speciesIDContent = (
      <p style={{margin: pluralityOfSpeciesIDCall ? "5px" : null}}>
        <b>
            <small>
          {speciesIDCall}
          {pluralityOfSpeciesIDCall ? <br /> : null}
          {pluralityOfSpeciesIDCall}</small>
        </b>
      </p>
    );
  }

  return (
    <Link ref={ref} to={isClickable ? `/projects/${project?.id}/runs/${sample?.attributes.sequencing_run_id}/samples/${sample?.id}` : null} style={{ textDecoration: 'none', color: 'black', cursor: isClickable ? 'pointer' : 'default', opacity: isClickable ? 1 : 0.5}}>
      <Box>
        <Grid
          container
          sx={{
              pl: 2, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', '&:hover': { backgroundColor: isClickable ? '#ff980015' : '' },
          }}
        >
          <Grid item xs={12} sm={2} sx={{ textAlign: 'left' }}>
            <p style={{
                'overflow': 'hidden',
                'whiteSpace': 'nowrap',
                'textOverflow': 'ellipsis'
            }}>
                {
                  activeRun?.attributes.name
                }
            </p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p style={{
                'overflow': 'hidden',
                'whiteSpace': 'nowrap',
                'textOverflow': 'ellipsis'
            }}>
              <b>
                {
                  sample.attributes.sample_name
                  || sample.attributes.sample_id
                  || sample.attributes.library_id
                  || sample.attributes.id
                }
              </b>
            </p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            { statusContent }
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            { yieldContent }
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            { speciesIDContent }
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p><small>{new Date(sample.attributes.created_at).toLocaleString()}</small></p>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
}

export default SampleInDatasetListItem;
