import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUbuntu, faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

const styles = {
    iconContainer: {
      padding: "13px 20px"
    },
    labelContainer: {
      padding: "13px 20px"
    },
    label: {
      color: "gray",
      fontWeight: "bold"
    },
    downloadContainer: {
      textAlign: "right",
      padding: "5px 20px"
    },
    downloadButton: {
      color: "primary.main"
    },
    container: {
        borderBottom: '2px solid #e0e0e0'
    }
  };

const getPlatformDetails = (platform) => {
    switch (platform?.toLowerCase()) {
        case 'linux':
            return {
                icon: faUbuntu,
                label: 'Ubuntu (20.04)',
            };
        case 'macos_intel':
            return {
                icon: faApple,
                label: 'MacOS (Intel)',
            };
        case 'macos_arm64':
            return {
                icon: faApple,
                label: 'MacOS (Apple Silicon)',
            };
        default:
            return {
                icon: faWindows,
                label: 'Windows',
            };
    }
};


function DownloadListItem({ platform }) {
    const { getAccessTokenSilently } = useAuth0();
    const { organization, setUserErrorMessage } = useOutletContext()

    const [isDownloading, setIsDownloading] = useState(false);

    const downloadCallback = useCallback(async () => {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently();
      try {
        await downloadBinary(platform, organization, accessToken);
      } catch (error) {
        setUserErrorMessage('Failed to download Keynome Manager! Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.');
        console.error("Error downloading Keynome Manager binary:", error);
      } finally {
        setIsDownloading(false);
      }
    }, [getAccessTokenSilently, setUserErrorMessage, organization, platform]);

    return (
      <Box sx={styles.container}>
        <Grid container>
          <Grid item xs={3} sx={styles.iconContainer}>
            <FontAwesomeIcon icon={getPlatformDetails(platform).icon} size="lg" />
          </Grid>
          <Grid item xs={7} sx={styles.labelContainer}>
            <Typography sx={styles.label}>{getPlatformDetails(platform).label}</Typography>
          </Grid>
          <Grid item xs={2} sx={styles.downloadContainer}>
            {
              isDownloading 
                ? <CircularProgress size="30px" thickness="6"/> 
                : <IconButton 
                    sx={styles.downloadButton} 
                    aria-label={`download for ${platform}`}
                    onClick={downloadCallback}
                  >
                    <DownloadIcon />
                  </IconButton>
            }
          </Grid>
        </Grid>
      </Box>
    );
}

// ============================================================================================== //
//                     HELPER FUNCTIONS FOR DOWNLOADING KEYNOME MANAGER BINARIES                  //
// ============================================================================================== //


async function downloadBinary(platform, organization, accessToken) {
  const base_url = process.env.REACT_APP_KEYNOME_API_URL_BASE;
  const extra_field = `keynome_manager_${platform}_download_signed_url`;
  const url = `${base_url}/v1/organizations/${organization.id}?extra_fields=${extra_field}`;

  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  });
  const res = await fetch(url, { method: 'GET', headers });
  if (!res.ok) {
    throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
  };

  const response = await res.json();
  const signed_url = response.data.attributes[extra_field];
  await downloadFromSignedUrl(signed_url, platform);
}


async function downloadFromSignedUrl(signed_url, platform) {
  const res = await fetch(signed_url, { method: 'GET' });
  if (!res.ok) {
    throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
  }

  const blob = await res.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = binaryFilenameForPlatform(platform);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);  
}


function binaryFilenameForPlatform(platform) {
  switch (platform?.toLowerCase()) {
    case 'windows':
      return 'keynome-manager.exe'
    default:
      return 'keynome-manager'
  }
}

export default DownloadListItem;
