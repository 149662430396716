import React, { useState } from 'react';

import { IdentifiedSpeciesContent, IdentifiedVirusContent, IdentifiedSpeciesDisplaySummary } from './IdentifiedSpeciesContent';
import IdentifiedSpeciesMenu from './IdentifiedSpeciesMenu';
import { Box, Grid, Paper, Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import ResistanceGenesDialog from './ResistanceGenesDialog';

export const IdentifiedOrganismsBox = ({
    isDiagnosticView,
    visibleSpeciesCt,
    identifiedSpecies,
    showKIDCalls,
    onTuneClick,
    isIllumina,
    run,
    sample,
    analysis,
    showAST,
    showRnDModels,
    showImputedCalls,
    showAssemblyMetrics,
    showAssemblyMBsToSpecies,
    showContigsToSpecies,
    showAlignmentMetrics,
    showCoverageBreadth,
    showCoverageDepth,
    showResistanceGenes,
    totalMicrobialMegabases,
    setLoadingResistanceGenes,
    setLoadingImputations,
    organization
  }) => {
    const [isResistanceGeneDialogOpen, setIsResistanceGeneDialogOpen] = useState(false);
    const [allAvailableResistanceGenesLoading, setAllAvailableResistanceGenesLoading] = useState(true);
    
    const hasAccessExtendedResistanceGenePanel = organization?.attributes?.metadata?.FF_expanded_resistance_gene_panel === true;
  
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={2}>
          <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
              <Grid container>
                <Grid item xs={2.5}>
                  <p>
                    <b>
                      {isDiagnosticView ? 'DETECTED' : 'IDENTIFIED'} ORGANISMS
                    </b>
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p><IdentifiedSpeciesDisplaySummary
                    identifiedSpecies={identifiedSpecies}
                    visibleSpeciesCt={visibleSpeciesCt}
                    showKIDCalls={showKIDCalls}
                  /></p>
                </Grid>
                <Grid item xs={3.5} sx={{
                  textAlign: 'right', 
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                  {!isDiagnosticView && (
                    <Button 
                      size="small" 
                      color="primary" 
                      variant="contained"
                      aria-label="open-tune-dialog"
                      onClick={onTuneClick}
                      startIcon={<TuneIcon />}
                    >
                      CHANGE RESULTS DISPLAY
                    </Button>
                  )}
                  <IdentifiedSpeciesMenu
                    loading={!identifiedSpecies}
                    allAvailableResistanceGenesLoading={allAvailableResistanceGenesLoading}
                    isIllumina={isIllumina}
                    run={run}
                    sample={sample}
                    analysis={analysis}
                    identifiedSpeciesList={identifiedSpecies}
                    totalMicrobialMegabases={totalMicrobialMegabases}
                    setIsResistanceGeneDialogOpen={setIsResistanceGeneDialogOpen}
                    hasAccessExtendedResistanceGenePanel={hasAccessExtendedResistanceGenePanel}
                  />
                  <ResistanceGenesDialog
                      open={isResistanceGeneDialogOpen}
                      onClose={() => setIsResistanceGeneDialogOpen(false)}
                      identifiedSpecies={identifiedSpecies}
                      setAllAvailableResistanceGenesLoading={setAllAvailableResistanceGenesLoading}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <IdentifiedSpeciesContent
                isIllumina={isIllumina}
                isDiagnosticView={isDiagnosticView}
                q20Rate={analysis?.attributes.q20_rate}
                assemblyN50={analysis?.attributes.assembly_n50}
                assemblyCoverageLT2xDepth={analysis?.attributes.assembly_ratio_lt_2x_coverage}
                loading={!identifiedSpecies}
                identifiedSpecies={identifiedSpecies}
                visibleSpeciesCt={visibleSpeciesCt}
                showKIDCalls={showKIDCalls}
                showAST={showAST}
                showRnDModels={showRnDModels}
                showImputedCalls={showImputedCalls}
                showAssemblyMetrics={showAssemblyMetrics}
                showAssemblyMBsToSpecies={showAssemblyMBsToSpecies}
                showContigsToSpecies={showContigsToSpecies}
                showAlignmentMetrics={showAlignmentMetrics}
                showCoverageBreadth={showCoverageBreadth}
                showCoverageDepth={showCoverageDepth}
                showResistanceGenes={showResistanceGenes}
                totalMicrobialMegabases={totalMicrobialMegabases}
                setLoadingResistanceGenes={setLoadingResistanceGenes}
                setLoadingImputations={setLoadingImputations}
              />
              <IdentifiedVirusContent
                isIllumina={isIllumina}
                isDiagnosticView={isDiagnosticView}
                q20Rate={analysis?.attributes.q20_rate}
                assemblyN50={analysis?.attributes.assembly_n50}
                assemblyCoverageLT2xDepth={analysis?.attributes.assembly_ratio_lt_2x_coverage}
                loading={!identifiedSpecies}
                identifiedSpecies={identifiedSpecies}
                visibleSpeciesCt={visibleSpeciesCt}
                showKIDCalls={showKIDCalls}
                totalMicrobialMegabases={totalMicrobialMegabases}
                setLoadingResistanceGenes={setLoadingResistanceGenes}
                setLoadingImputations={setLoadingImputations}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IdentifiedOrganismsBox;
