import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import SequencingYieldPieChart from './SequencingYieldPieChart';
import SequencingYieldStats from './SequencingYieldStats';
import HumanDNADialog from './HumanDNADiaglog';


function DetailedSequencingYieldSummary(props) {
  const {
    analysis,
    microbialMegabases,
    microbialReads
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [isHumanDataDialogOpen, setIsHumanDataDialogOpen] = useState(false);
  const [sequencingFileData, setSequecingFileData] = useState(null);

  /**
   * On change to analysis, retrieve data for associated sequencing file
   * to report on human DNA deletion
   */
  useEffect(() => {
    if (!analysis) {
      return;
    }
  
    const fetchSequencingData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });
  
        const execResponse = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analysis_executions/${analysis.attributes.flow_execution_id}`,
          {
            method: 'GET',
            headers,
          }
        );
  
        if (!execResponse.ok) {
          throw new Error(`Request failed with ${execResponse.status} (${execResponse.statusText})`);
        }
  
        const execData = await execResponse.json();
        const fastqResponse = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/concatenated_fastqgzs/${execData.data.attributes.concatenated_fastqgz_id}`,
          {
            method: 'GET',
            headers,
          }
        );
  
        if (!fastqResponse.ok) {
          throw new Error(`Request failed with ${fastqResponse.status} (${fastqResponse.statusText})`);
        }
  
        const fastqData = await fastqResponse.json();
        setSequecingFileData(fastqData.data);
      } catch (error) {
        datadogRum.addError(error);
      }
    };
  
    fetchSequencingData();
  }, [getAccessTokenSilently, analysis]);

  if (!analysis) {
    return
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SEQUENCING YIELD</b></p>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SequencingYieldStats
            analysis={analysis}
            microbialMegabases={microbialMegabases}
            microbialReads={microbialReads}
            sequencingFileData={sequencingFileData}
            setIsHumanDataDialogOpen={setIsHumanDataDialogOpen}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{paddingRight: '90px', paddingBottom: '20px'}}>
          {analysis ? <SequencingYieldPieChart analysisAttributes={analysis.attributes} showHuman={sequencingFileData && !sequencingFileData.attributes.human_dna_deleted_at} /> : null}
        </Grid>
      </Grid>

      <HumanDNADialog
        open={isHumanDataDialogOpen}
        onClose={() => setIsHumanDataDialogOpen(false)}
        sequencingFileData={sequencingFileData}
      />
    </>
  );
}

export default DetailedSequencingYieldSummary;
