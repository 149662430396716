export const calculateSequencingStats = (analysis, isIllumina, microbialMegabases, microbialReads) => {
    const totalMegabases = isIllumina
      ? (analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2)
      : analysis.attributes.raw_total_megabases;
  
    const downsampledMegabases = isIllumina
      ? (analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2) - (analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2)
      : (analysis.attributes.raw_total_megabases - analysis.attributes.downsampled_megabases);
  
    const downsampledReads = isIllumina
      ? (analysis.attributes.qc_filtered_reads - analysis.attributes.downsampled_reads)
      : (analysis.attributes.raw_total_reads - analysis.attributes.downsampled_reads);
  
    const qcFilteredMegabases = isIllumina
      ? ((analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) - (analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2))
      : (analysis.attributes.downsampled_megabases - analysis.attributes.qc_filtered_megabases);
  
    const qcFilteredReads = isIllumina
      ? (analysis.attributes.raw_total_reads - analysis.attributes.qc_filtered_reads)
      : (analysis.attributes.downsampled_reads - analysis.attributes.qc_filtered_reads);
  
    const humanReadsRemovedMegabases = isIllumina
      ? ((analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2) - (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2))
      : (analysis.attributes.qc_filtered_megabases - analysis.attributes.human_reads_removed_megabases);
  
    const humanReadsRemovedReads = isIllumina
      ? (analysis.attributes.downsampled_reads - analysis.attributes.human_reads_removed_reads)
      : (analysis.attributes.qc_filtered_reads - analysis.attributes.human_reads_removed_reads);
  
    const analyzedMegabases = isIllumina
      ? (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2)
      : analysis.attributes.human_reads_removed_megabases;

    const unclassifiedMegabases = (analyzedMegabases - microbialMegabases) || null;

    const unclassifiedReads = (analysis?.attributes.human_reads_removed_reads - microbialReads) || null;
        
  
    return {
      totalMegabases,
      downsampledMegabases,
      downsampledReads,
      qcFilteredMegabases,
      qcFilteredReads,
      humanReadsRemovedMegabases,
      humanReadsRemovedReads,
      analyzedMegabases,
      unclassifiedMegabases,
      unclassifiedReads,
    };
  };

 export const getTimeElapsed = (start, end) => {
    const diff = new Date(end) - new Date(start);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  };

export const formatBaseCount = (megabases) => {
  if (!megabases && megabases !== 0) return '-';
  
  if (megabases < 0.005) {
    const bases = Math.round(megabases * 1000000);
    return `${bases.toLocaleString()} bases`;
  }
  
  return `${megabases.toLocaleString()} MBs`;
};