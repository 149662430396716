/**
 * Utilizes a reference of all predictions for a sample to determine the prediction
 * and associated antibiotic that an imputation was derived from.
 */
export function imputedFromAntibiotic(allPredictionsReference, imputationAttributes) {
  if (!imputationAttributes) {
    return false;
  }

  return allPredictionsReference[imputationAttributes.amr_model_prediction_id].attributes.model_drug
}