// KeynomeManagerDownload.jsx
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';


const styles = {
  instructionsContainer: {
    padding: "20px"
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 3
  },
  instructionsTitle: {
    fontWeight: "bold"
  },
  tabs: {
    minHeight: '40px',
    '& .MuiTab-root': {
      minHeight: '40px',
      padding: '6px 16px'
    }
  },
  codeBlock: {
    backgroundColor: "#eee",
    padding: "2px 6px",
    borderRadius: "5px"
  },
  listItem: {
    marginBottom: "8px"
  },
  orderedList: {
    margin: 0,
    paddingLeft: "20px"
  },
  osSpecificInstructions: {
    mb: 1,
    mt: 2
  },
};


function InstructionTabPanel(props) {
  const { children, selectedPlatform, index } = props;

  return (
    <div hidden={selectedPlatform !== index}>
      {children}
    </div>
  );
}


function KeynomeManagerDownloadInstructions() {
  const [selectedPlatform, setSelectedPlatform] = useState("windows");

  const changeTab = (event, newSelectedPlatform) => {
    setSelectedPlatform(newSelectedPlatform);
  }

  return (
    <Box sx={styles.instructionsContainer}>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.instructionsTitle}>
          Installation Instructions
        </Typography>

        <Tabs 
          value={selectedPlatform} 
          onChange={changeTab}
          sx={styles.tabs}
        >
          <Tab value="windows" label="Windows" />
          <Tab value="linux" label="Linux" />
          <Tab value="macos" label="MacOS" />
        </Tabs>
      </Box>

      <Box sx={styles.osSpecificInstructions}>

        {/* ========== WINDOWS INSTRUCTIONS ========== */}
        <InstructionTabPanel selectedPlatform={selectedPlatform} index="windows">
          <ol style={styles.orderedList}>
            <li style={styles.listItem}>
              Download the <code style={styles.codeBlock}>keynome-manager.exe</code> executable for Windows and place it somewhere in your filesystem (e.g. in <code style={styles.codeBlock}>C:\Program Files\keynome\keynome-manager.exe</code>).
            </li>
            <li style={styles.listItem}>
              Make sure that this directory is on your <code style={styles.codeBlock}>Path</code>. Navigate to "Control Panel {'>'} System {'>'} Advanced System Options {'>'} Environment Variables". Select the <code style={styles.codeBlock}>Path</code> user variable and click "Edit". Click "New" and then "Browse", and then navigate to the directory in the previous step. Confirm all selection in open menus by clicking "OK".
            </li>
            <li style={styles.listItem}>
              Confirm your installation with <code style={styles.codeBlock}>keynome-manager --help</code> in a new Command Prompt window.
            </li>
            <li style={styles.listItem}>
              Click your initials at the top right of the page {'>'} "Documentation" {'>'} "Upload Sequencing Data - Keynome Manager" to begin your first upload.
            </li>
          </ol>
        </InstructionTabPanel>

        {/* ========== LINUX INSTRUCTIONS ========== */}
        <InstructionTabPanel selectedPlatform={selectedPlatform} index="linux">
          <ol style={styles.orderedList}>
            <li style={styles.listItem}>
              Download the <code style={styles.codeBlock}>keynome-manager</code> binary for Linux (Ubuntu) and place it somewhere in your filesystem (e.g. in <code style={styles.codeBlock}>~/.keynome/bin/keynome-manager</code>).
            </li>
            <li style={styles.listItem}>
              Make sure that this directory is on your <code style={styles.codeBlock}>PATH</code> (i.e. add <code style={styles.codeBlock}>export PATH=$PATH:$HOME/.keynome/bin</code> to your <code style={styles.codeBlock}>.bashrc</code>)
            </li>
            <li style={styles.listItem}>
              Run <code style={styles.codeBlock}>chmod +x ~/.keynome/bin/keynome-manager</code>
            </li>
            <li style={styles.listItem}>
              Confirm your installation with <code style={styles.codeBlock}>keynome-manager --help</code>
            </li>
            <li style={styles.listItem}>
              Click your initials at the top right of the page {'>'} "Documentation" {'>'} "Upload Sequencing Data - Keynome Manager" to begin your first upload.
            </li>
          </ol>
        </InstructionTabPanel>

        {/* ========== MACOS INSTRUCTIONS ========== */}
        <InstructionTabPanel selectedPlatform={selectedPlatform} index="macos">
          <ol style={styles.orderedList}>
            <li style={styles.listItem}>
              Download the <code style={styles.codeBlock}>keynome-manager</code> binary for MacOS and place it somewhere in your filesystem (e.g. in <code style={styles.codeBlock}>~/.keynome/bin/keynome-manager</code>). Make sure you select the correct CPU type for your machine (i.e. Intel vs. Silicon).
            </li>
            <li style={styles.listItem}>
              Make sure that this directory is on your <code style={styles.codeBlock}>PATH</code> (i.e. add <code style={styles.codeBlock}>export PATH=$PATH:$HOME/.keynome/bin</code> to your <code style={styles.codeBlock}>.zshrc</code>)
            </li>
            <li style={styles.listItem}>
              Run <code style={styles.codeBlock}>chmod +x ~/.keynome/bin/keynome-manager</code>
            </li>
            <li style={styles.listItem}>
              Click your initials at the top right of the page {'>'} "Documentation" {'>'} "Upload Sequencing Data - Keynome Manager" to being your first upload.
            </li>

            <Alert
              severity="warning"
              sx={{ my: 2 }}
            >
              You may need to configure your machine to trust the program via the "Privacy & Security" section in your "System Settings"
            </Alert>

            <li style={styles.listItem}>
              Click your initials at the top right of the page {'>'} "Documentation" {'>'} "Upload Sequencing Data - Keynome Manager" to being your first upload.
            </li>
          </ol>
        </InstructionTabPanel>
      </Box>
    </Box>
  );
}

export default KeynomeManagerDownloadInstructions;
