
import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import { Grid, Menu, MenuItem, IconButton, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { mkConfig, generateCsv, download } from "export-to-csv";
import useKeynomeApi, { chunkArray } from './api';

import { imputedFromAntibiotic } from '../AMRPredictions/imputationUtils.js';
import resistanceGenesReference from '../AMRPredictions/resistanceGenesReference';
import aggregateKgASTQualityLimitations from './KgASTQualityLimitations.js';


export function ExportIdentifiedSpeciesMenuItem(props) {   
    const {
        isIllumina,
        isDatasetLevel,
        run,
        samples,
        handleMenuClose,
        isExporting,
        setIsExporting,
    } = props;

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { fetchPrimaryAnalysisBySample, fetchAnalysis, fetchIdentifiedSpecies } = useKeynomeApi();
    const { setUserErrorMessage, setProgressMessage, hasAccessReportViruses } = useOutletContext();

    const fetchIdentifiedSpeciesDataForSample = useCallback(async (accessToken, sample) => {
        let analysis = await fetchPrimaryAnalysisBySample(accessToken, sample);
        analysis = analysis ? await fetchAnalysis(accessToken, analysis.id): null; // get status
        if (!analysis || analysis.attributes.status !== "succeeded") {return [];}

        const identifiedSpecies = await fetchIdentifiedSpecies(accessToken, analysis, isIllumina, hasAccessReportViruses);
        const totalMicrobialMegabases = identifiedSpecies.all.reduce((sum, species) => sum + (species.attributes.reads_megabases_to_species ?? 0), 0);

        const getIdentifiedSpeciesRow = (identifiedSpecies) => {
            const identifiedSpeciesRow = {
                "dataset_name": run.attributes.name,
                "sample_name": sample.attributes.sample_name,
                "sample_type": sample.attributes.sample_type,
                "analysis_pipeline_version": analysis?.attributes.metadata.minion_pipeline_version,
                "analysis_started_at": analysis.attributes.created_at,
                "analysis_completed_at": analysis.attributes.updated_at,
                "organism": identifiedSpecies.attributes.species,
                [isIllumina ? "read_pairs_to_organism" : "reads_to_organism"]: identifiedSpecies.attributes.reads_to_species || "",
                "megabases_to_organism": identifiedSpecies.attributes.reads_megabases_to_species || "",
                "percent_microbial_megabases_to_organism": (
                    (identifiedSpecies.attributes.reads_megabases_to_species && totalMicrobialMegabases) ?
                    identifiedSpecies.attributes.reads_megabases_to_species / totalMicrobialMegabases * 100 : ""
                ),
            };
    
            if (isIllumina) {
                return {
                    ...identifiedSpeciesRow,
                    "contigs_to_species": identifiedSpecies.attributes.contigs_to_species,
                    "assembly_megabases_to_species": identifiedSpecies.attributes.assembly_megabases_to_species,
                };
            }
            
            return {
                ...identifiedSpeciesRow,
                "kid_call_positive": identifiedSpecies.attributes.species_call_positive,
                "kid_value": identifiedSpecies.attributes.species_call_raw_value,
                "percent_reference_coverage": identifiedSpecies.attributes.perc_nonzero_coverage * 100 || "",
                "average_depth": identifiedSpecies.attributes.avg_depth || "",
            };
        };
        
        return Promise.all(identifiedSpecies.accessible.map(getIdentifiedSpeciesRow))
    }, [fetchPrimaryAnalysisBySample, fetchAnalysis, fetchIdentifiedSpecies, isIllumina, run, hasAccessReportViruses]);

    const handleExportClick = useCallback(async () => {
        setIsExporting(true);
        setProgressMessage({"status": "inProgress", "message": "Exporting Identified Organisms report"});

        try {
            const accessToken = await getAccessTokenSilently();
            const identifiedSpeciesRowsBySample = await Promise.all(samples.map(sample => fetchIdentifiedSpeciesDataForSample(accessToken, sample)));
            const identifiedSpeciesRows = identifiedSpeciesRowsBySample.flat();

            const csvConfig = mkConfig({
                useKeysAsHeaders: true,
                filename: (isDatasetLevel ? run.attributes.name : samples[0].attributes.sample_name ) + '_organism_id'
            });
            const csv = generateCsv(csvConfig)(identifiedSpeciesRows);
            download(csvConfig)(csv);

            setProgressMessage({"status": "completed", "message": "Identified Organisms export completed."});
        }
        catch (error) {
            setProgressMessage(null);
            setUserErrorMessage(
                'Failed to export Identified Organisms! Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.'
            );
            throw new Error(`Request to export Identified Organisms for ${samples.map(sample => sample.attributes.sample_name)} in dataset ${run.attributes.name} failed: ${error}`);
        }
        finally {
            setIsExporting(false);
        }
    }, [getAccessTokenSilently, fetchIdentifiedSpeciesDataForSample, setIsExporting, isDatasetLevel, run, samples, setProgressMessage, setUserErrorMessage]);

    return <MenuItem disabled={!isAuthenticated || isExporting} onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleExportClick();
        handleMenuClose();
    }}>
        Export Identified Organisms
    </MenuItem>
}

export function ExportKgASTPredictionsMenuItem(props) {   
    const {
        isIllumina,
        isDatasetLevel,
        run,
        samples,
        handleMenuClose,
        isExporting,
        setIsExporting,
    } = props;

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { fetchPrimaryAnalysisBySample, fetchAnalysis, fetchIdentifiedSpecies, fetchAmrPredictions, fetchImputations, fetchResistanceGenes } = useKeynomeApi();
    const { setUserErrorMessage, setProgressMessage, hasAccessReportViruses } = useOutletContext();

    const fetchKgASTDataForSpecies = useCallback(async (accessToken, sample, analysis, identifiedSpecies) => {
        const commonColumns = {
            "dataset_name": run.attributes.name,
            "sample_name": sample.attributes.sample_name,
            "sample_type": sample.attributes.sample_type,
            "analysis_pipeline_version": analysis?.attributes.metadata.minion_pipeline_version,
            "analysis_started_at": analysis.attributes.created_at,
            "analysis_completed_at": analysis.attributes.updated_at,
            "organism": identifiedSpecies.attributes.species,
            "antibiotic": "",
            "amr_call": "",
            "kgast_model_performance_class": "",
            "resistance_genes": "",
            "amr_call_imputed_from": "",
            "passes_qc": aggregateKgASTQualityLimitations(
                isIllumina,
                identifiedSpecies.attributes,
                analysis?.attributes.q20_rate,
                analysis?.attributes.assembly_n50,
                analysis?.attributes.assembly_ratio_lt_2x_coverage
            ).length === 0,
        };

        const amrPredictions = await fetchAmrPredictions(accessToken, identifiedSpecies, null);
        if (!amrPredictions.high_confidence && !amrPredictions.medium_confidence) {return [commonColumns];}

        const allAmrPredictions = {...amrPredictions.high_confidence, ...amrPredictions.medium_confidence};

        // Fetch imputations and resistance genes in parallel
        const [imputations, resistanceGenes] = await Promise.all([
            fetchImputations(
                accessToken,
                amrPredictions.high_confidence,
                Object.values(allAmrPredictions).map(item => item.attributes.model_drug)
            ),
            fetchResistanceGenes(accessToken, identifiedSpecies)
        ]);

        const kgASTDataByDrug = new Map();
        Object.values(amrPredictions.high_confidence).forEach(prediction => {
            kgASTDataByDrug.set(
                prediction.attributes.model_drug,
                {
                    "antibiotic": prediction.attributes.model_drug,
                    "amr_call": prediction.attributes.amr_call,
                    "kgast_model_performance_class": prediction.attributes.model_performance_label,
                }
            )
        });
        imputations.forEach(imputation => {
            kgASTDataByDrug.set(
                imputation.attributes.drug,
                {
                    "antibiotic": imputation.attributes.drug,
                    "amr_call": imputation.attributes.call,
                    "amr_call_imputed_from": imputedFromAntibiotic(allAmrPredictions, imputation.attributes),
                }
            )
        });
        resistanceGenes.forEach(hierarchyNode => {
            const nodeDetails = resistanceGenesReference[hierarchyNode];
            if (nodeDetails) {
                nodeDetails.antibiotics.forEach(antibiotic => {
                    kgASTDataByDrug.set(
                        antibiotic,
                        {
                            ...(kgASTDataByDrug.get(antibiotic) || {}),
                            "antibiotic": antibiotic,
                            "resistance_genes": nodeDetails.display_name,
                        }
                    )
                });
            }
        });

        const kgASTRows = Array.from(
            kgASTDataByDrug.values()
            .map(item => {return {...commonColumns, ...item};})
        );
        return kgASTRows.sort((a, b) => a.antibiotic.localeCompare(b.antibiotic));
    }, [fetchAmrPredictions, fetchImputations, fetchResistanceGenes, isIllumina, run]);

    const fetchKgASTDataForSample = useCallback(async (accessToken, sample) => {
        let analysis = await fetchPrimaryAnalysisBySample(accessToken, sample);
        analysis = analysis ? await fetchAnalysis(accessToken, analysis.id): null; // get status
        if (!analysis || analysis.attributes.status !== "succeeded") {return [];}

        const identifiedSpecies = await fetchIdentifiedSpecies(accessToken, analysis, isIllumina, hasAccessReportViruses);
        
        const speciesChunks = chunkArray(identifiedSpecies.accessible, 5);
        let KgASTRows = [];

        for (const chunk of speciesChunks) {
            KgASTRows = KgASTRows.concat(
                await Promise.all(chunk.map(species => fetchKgASTDataForSpecies(accessToken, sample, analysis, species)))
            );
        }

        return KgASTRows.flat();
    }, [fetchPrimaryAnalysisBySample, fetchAnalysis, fetchIdentifiedSpecies, fetchKgASTDataForSpecies, isIllumina, hasAccessReportViruses]);

    const handleExportClick = useCallback(async () => {
        setIsExporting(true);
        setProgressMessage({"status": "inProgress", "message": "Exporting AMR Predictions report"});

        try {
            const accessToken = await getAccessTokenSilently();
            const kgASTRowsBySample = await Promise.all(samples.map(sample => fetchKgASTDataForSample(accessToken, sample)));
            const kgASTRows = kgASTRowsBySample.flat();

            const csvConfig = mkConfig({
                useKeysAsHeaders: true,
                filename: (isDatasetLevel ? run.attributes.name : samples[0].attributes.sample_name ) + '_amr_predictions'
            });
            const csv = generateCsv(csvConfig)(kgASTRows);
            download(csvConfig)(csv);

            setProgressMessage({"status": "completed", "message": "AMR Predictions export completed."});
        } catch (error) {
            setProgressMessage(null);
                setUserErrorMessage(
                    'Failed to export AMR Predictions! Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.'
                );
                throw new Error(`Request to export AMR Predictions for ${samples.map(sample => sample.attributes.sample_name)} in dataset ${run.attributes.name} failed: ${error}`);
        } finally {
            setIsExporting(false);
        }
    }, [getAccessTokenSilently, fetchKgASTDataForSample, setIsExporting, isDatasetLevel, run, samples, setProgressMessage, setUserErrorMessage]);

    return <MenuItem disabled={!isAuthenticated || isExporting} onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleExportClick();
        handleMenuClose();
    }}>
        Export AMR Predictions
    </MenuItem>

}

function IdentifiedSpeciesMenu(props) {
    const {
        loading,
        allAvailableResistanceGenesLoading,
        isIllumina,
        run,
        sample,
        setIsResistanceGeneDialogOpen,
        hasAccessExtendedResistanceGenePanel
    } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const open = Boolean(menuAnchorEl);
    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
      
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const [isExporting, setIsExporting] = useState(false);

    return (
        <Grid item sx={{textAlign: 'right'}}>
            <IconButton size="small" onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {hasAccessExtendedResistanceGenePanel &&
                    <MenuItem disabled={loading || allAvailableResistanceGenesLoading} onClick={() => {
                        setIsResistanceGeneDialogOpen(true);
                        handleMenuClose();
                    }}>
                        Complete Resistance Gene Profile
                        {allAvailableResistanceGenesLoading && (
                            <CircularProgress size={16} sx={{ ml: 1 }} />
                        )}
                    </MenuItem>
                }
                <ExportIdentifiedSpeciesMenuItem
                    isIllumina={isIllumina}
                    isDatasetLevel={false}
                    run={run}
                    samples={[sample]}
                    handleMenuClose={handleMenuClose}
                    isExporting={isExporting}
                    setIsExporting={setIsExporting}
                />
                <ExportKgASTPredictionsMenuItem
                    isIllumina={isIllumina}
                    isDatasetLevel={false}
                    run={run}
                    samples={[sample]}
                    handleMenuClose={handleMenuClose}
                    isExporting={isExporting}
                    setIsExporting={setIsExporting}
                />
            </Menu>
        </Grid>
    );
}

export default IdentifiedSpeciesMenu;