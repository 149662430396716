import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, Accordion, AccordionSummary, Typography, Grid } from '@mui/material';

import IdentifiedSpeciesRow from './IdentifiedSpeciesRow';
import { identifiedSpeciesIsVirus } from './utilities';

export const IdentifiedSpeciesDisplaySummary = ({
  identifiedSpecies,
  visibleSpeciesCt,
  showKIDCalls,
  isViral,
}) => {
  const getSpeciesStats = (species) => {
    if (!species) return { positive: 0, subthreshold: 0 };
    return {
      positive: species.filter(item => item.attributes.species_call_positive).length,
      subthreshold: species.filter(item => !item.attributes.species_call_positive).length
    };
  };

  const { positive, subthreshold } = getSpeciesStats(identifiedSpecies);

  return <Typography component="span">
      <small>
      Displaying data for <b>{visibleSpeciesCt}</b> of <b>{identifiedSpecies?.length}</b>
      {isViral ? ' viral': ' total'} 
      {(showKIDCalls && identifiedSpecies) ? ` (${positive} positive; ${subthreshold} subthreshold) ` : ' '}
      organisms
      </small>
    </Typography>
}

export const IdentifiedSpeciesContent = ({
    loading,
    identifiedSpecies,
    isDiagnosticView,
    visibleSpeciesCt,
    isIllumina,
    showAST,
    showKIDCalls,
    showRnDModels,
    showImputedCalls,
    showAlignmentMetrics,
    showCoverageBreadth,
    showCoverageDepth,
    showAssemblyMetrics,
    showAssemblyMBsToSpecies,
    showContigsToSpecies,
    showResistanceGenes,
    q20Rate,
    assemblyN50,
    assemblyCoverageLT2xDepth,
    totalMicrobialMegabases,
    setLoadingResistanceGenes,
    setLoadingImputations,
}) => {
  if (loading || !identifiedSpecies) return <LinearProgress />;
  return identifiedSpecies
    .filter(item => !isDiagnosticView || item.attributes.species_call_positive)
    .slice(0, isDiagnosticView ? 5 : visibleSpeciesCt)
    .filter(item => !identifiedSpeciesIsVirus(item))
    .map((item, index) => (
      <IdentifiedSpeciesRow
        key={index}
        identifiedSpecies={item}
        isIllumina={isIllumina}
        isDiagnosticView={isDiagnosticView}
        showAST={showAST}
        showKIDCalls={showKIDCalls}
        showRnDModels={showRnDModels}
        showImputedCalls={showImputedCalls}
        showAlignmentMetrics={showAlignmentMetrics}
        showCoverageBreadth={showCoverageBreadth}
        showCoverageDepth={showCoverageDepth}
        showAssemblyMetrics={showAssemblyMetrics}
        showAssemblyMBsToSpecies={showAssemblyMBsToSpecies}
        showContigsToSpecies={showContigsToSpecies}
        showResistanceGenes={showResistanceGenes}
        q20Rate={q20Rate}
        assemblyN50={assemblyN50}
        assemblyCoverageLT2xDepth={assemblyCoverageLT2xDepth}
        totalMicrobialMegabases={totalMicrobialMegabases}
        setLoadingResistanceGenes={setLoadingResistanceGenes}
        setLoadingImputations={setLoadingImputations}
      />
    ));
};

export const IdentifiedVirusContent = ({
  loading,
  identifiedSpecies,
  isDiagnosticView,
  visibleSpeciesCt,
  isIllumina,
  showKIDCalls,
  q20Rate,
  assemblyN50,
  assemblyCoverageLT2xDepth,
  totalMicrobialMegabases,
  setLoadingResistanceGenes,
  setLoadingImputations,
}) => {
  const [ expanded, setExpanded ] = useState(false);
  const handleAccordionChange = () => {setExpanded((prev) => !prev)};

  const viralIdentifiedSpecies = identifiedSpecies?.filter(item => identifiedSpeciesIsVirus(item));

  if (loading || !identifiedSpecies || !viralIdentifiedSpecies.length) return <></>;

  const visibleViralIdentifiedSpecies = identifiedSpecies
    .slice(0, isDiagnosticView ? 5 : visibleSpeciesCt)
    .filter(item => identifiedSpeciesIsVirus(item));

  return <Accordion disableGutters expanded={expanded} onChange={handleAccordionChange}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="viral-identified-species-content"
      id="viral-identified-species-header"
      sx={{ backgroundColor: '#eee', padding: '1px 10px' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2.5}>
          <Typography component="span">
            <b>{viralIdentifiedSpecies.length}</b> viruses identified
          </Typography>
        </Grid>
        {
          expanded &&
          <Grid item xs={6}>
            <IdentifiedSpeciesDisplaySummary
              identifiedSpecies={viralIdentifiedSpecies}
              visibleSpeciesCt={visibleViralIdentifiedSpecies.length}
              showKIDCalls={showKIDCalls}
              isViral
            />
          </Grid>
        }
      </Grid>
    </AccordionSummary>
      {visibleViralIdentifiedSpecies.map((item, index) => (
        <IdentifiedSpeciesRow
          key={index}
          identifiedSpecies={item}
          isIllumina={isIllumina}
          isDiagnosticView={isDiagnosticView}
          showKIDCalls={showKIDCalls}
          q20Rate={q20Rate}
          assemblyN50={assemblyN50}
          assemblyCoverageLT2xDepth={assemblyCoverageLT2xDepth}
          totalMicrobialMegabases={totalMicrobialMegabases}
          setLoadingResistanceGenes={setLoadingResistanceGenes}
          setLoadingImputations={setLoadingImputations}
        />
      ))}
  </Accordion>
}