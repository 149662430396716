import React from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions,
  Typography, Button 
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getTimeElapsed, formatBaseCount } from './utils/sequencingStats';

export const styles = {
  dialogTitle: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    marginTop: '20px',
    padding: '20px 40px',
  },
  dialogActions: {
    backgroundColor: '#eee',
  },
  secondaryText: {
    color: 'text.secondary',
    fontSize: '0.875rem',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
};

const HumanDNADialog = ({ open, onClose, sequencingFileData }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle sx={styles.dialogTitle}>
      Human DNA Removal
    </DialogTitle>
    <DialogContent sx={styles.dialogContent}>
      <Typography paragraph sx={{ ...styles.flexRow }}>
        <CheckCircleIcon sx={{ color: 'success.main' }} />
        <b>Human DNA identified and permanently deleted</b>
      </Typography>
      
      {sequencingFileData && (
        <>
          <Typography paragraph>
            Original FASTQ uploaded {new Date(sequencingFileData.attributes.created_at).toLocaleString()}
          </Typography>
          <Typography paragraph>
            Human DNA permanently deleted {new Date(sequencingFileData.attributes.human_dna_deleted_at).toLocaleString()}
          </Typography>
          <Typography
            paragraph
            sx={{ ...styles.secondaryText, mt: -1 }}
          >
            (permanently deleted {getTimeElapsed(
              sequencingFileData.attributes.created_at,
              sequencingFileData.attributes.human_dna_deleted_at
            )} after upload)
          </Typography>
          <Typography paragraph>
            {formatBaseCount(sequencingFileData.attributes.human_dna_megabases + (sequencingFileData.attributes.human_dna_megabases_r2 || 0))}
            {' '}
            ({(sequencingFileData.attributes.human_dna_reads + 
              (sequencingFileData.attributes.human_dna_reads_r2 || 0)).toLocaleString()} reads) of human DNA removed
          </Typography>
        </>
      )}

      <Typography 
          paragraph 
          sx={{ ...styles.flexRow, ...styles.secondaryText }}
        >
        <LockIcon fontSize="small" />
        <b>Human DNA access protection confirmed</b>
      </Typography>
    </DialogContent>
    <DialogActions sx={styles.dialogActions}>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginRight: '20px' }}
        onClick={onClose}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default HumanDNADialog;