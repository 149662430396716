import { useCallback } from "react";

import { identifiedSpeciesIsVirus } from "./utilities";

export const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

const fetchWithRetry = async (url, options, retries = 3) => {
    const response = await fetch(url, options);

    if (response.ok || response.status !== 500 || retries === 0) {
        return response;
    }

    return (
        new Promise(resolve => setTimeout(resolve, 1000))
    ).then(() => fetchWithRetry(url, options, retries - 1));
};

export default function useKeynomeApi() {
    const fetchPrimaryAnalysisBySample = useCallback(async (accessToken, sample) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});
        const response = await fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sample.id}/analyses?primary_only=True`,
            {
                method: 'GET',
                headers,
            }
        );
        const jsonResponse = await response.json();

        if (!jsonResponse.data.length) {return null;}
        return jsonResponse.data[0];
    }, []);

    const fetchAnalysis = useCallback(async (accessToken, analysis_id) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});
        const response = await fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${analysis_id}?extra_fields=status`,
            {
                method: 'GET',
                headers,
            }
        );
        const jsonResponse = await response.json();
        return jsonResponse.data;
    }, []);

    const fetchIdentifiedSpecies = useCallback(async (accessToken, analysis, isIllumina, hasAccessReportViruses) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});
        const response = await fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${analysis.id}/identified_species`,
            {
                method: 'GET',
                headers,
            },
        )
        const jsonResponse = await response.json();

        const allIdentifiedSpecies = [...jsonResponse.data];
        const accessibleIdentifiedSpecies = allIdentifiedSpecies
        .sort((a, b) => 
            isIllumina
            ? b.attributes.reads_frac_agreeing_megabases - a.attributes.reads_frac_agreeing_megabases
            : a.attributes.species_call_rank_by_kid_value - b.attributes.species_call_rank_by_kid_value
        )

        // If the user does not have access view identified viruses, filter out identified viruses
        .filter(item => hasAccessReportViruses || !identifiedSpeciesIsVirus(item))

        // If is Illumina only allow the user to display organisms which have fraction agreeing
        // megabases >= 0.8 or have at least one contig in the raw assembly
        // If is ONT do not display organisms with rank 0 by KID value (reserved for IPC)
        .filter(item => 
            isIllumina
            ? (item.attributes.reads_frac_agreeing_megabases > 0.8 || item.attributes.contigs_to_species > 0) 
            : item.attributes.species_call_rank_by_kid_value !== 0
        );

        return {
            "all": allIdentifiedSpecies,
            "accessible": accessibleIdentifiedSpecies
        }
    }, []);

    const fetchAmrPredictions = useCallback(async (accessToken, identifiedSpecies, signal) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});
        const response = await fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/identified_species/${identifiedSpecies.id}/amr_model_predictions`,
            {
                method: 'GET',
                headers,
                signal
            },
        )
        const jsonResponse = await response.json();
        if (!jsonResponse.data.length) {
            return {"high_confidence": null, "medium_confidence": null};
        }

        const highConfidenceAmrPredictions = jsonResponse.data
            .filter(item => ['VH', 'H'].includes(item.attributes.model_performance_label))
            .reduce((acc, prediction) => {
                acc[prediction.id] = prediction
                return acc
            }, {})

        const mediumConfidenceAmrPredictions = jsonResponse.data
            .filter(item => item.attributes.model_performance_label === 'M')
            .reduce((acc, prediction) => {
                acc[prediction.id] = prediction
                return acc
            }, {})

        return {
            "high_confidence": highConfidenceAmrPredictions,
            "medium_confidence": mediumConfidenceAmrPredictions
        };
    }, []);

    const fetchImputations = useCallback(async (accessToken, amrPredictions, predictedDrugs) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});

        let responses = [];
        const imputationChunks = chunkArray(Object.values(amrPredictions), 5);
        for (const chunk of imputationChunks) {
            const chunkResponses = await Promise.all(
                chunk.map(prediction => {
                    return fetchWithRetry(
                        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/amr_model_predictions/${prediction.id}/imputations`,
                        {
                            method: 'GET',
                            headers,
                        },
                    )
                })
            );
            responses = responses.concat(chunkResponses);
        }
        const jsonResponses = await Promise.all(responses.map(res => res.json()));
        const imputedCalls = jsonResponses.reduce((acc, res) => acc.concat(res.data), []);
        return (!imputedCalls.length) ? [] : imputedCalls.filter(item => !!item && !predictedDrugs.includes(item.attributes.drug));
    }, []);

    const fetchResistanceGenes = useCallback(async (accessToken, identifiedSpecies) => {
        const headers = new Headers({Authorization: `Bearer ${accessToken}`});
        const response = await fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/identified_species/${identifiedSpecies.id}/resistance_genes?displayable_hierarchy_nodes_only=true`,
            {
                method: 'GET',
                headers,
            },
        );
        const jsonResponse = await response.json();
        const resistanceGenes = jsonResponse.data.reduce((acc, item) => {
            const hierarchyNode = item.attributes.hierarchy_node;
            if (!acc.has(hierarchyNode)) {
                acc.add(hierarchyNode);
            } 
            return acc;
        }, new Set());
        return resistanceGenes;
    }, []);

    return { fetchPrimaryAnalysisBySample, fetchAnalysis, fetchIdentifiedSpecies, fetchAmrPredictions, fetchImputations, fetchResistanceGenes };
}