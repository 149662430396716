const kgASTVersions = {
    "v0.7.5.1": {
        clsiVersion: 'CLSI M100 Ed32 [2022]',
        microhmDBVersion: 'v0.1.0'
    },
    "v0.7.6.0": {
        clsiVersion: 'CLSI M100 Ed34 [2024]',
        microhmDBVersion: 'v0.13.0'
    },
    "v0.7.7.0": {
        clsiVersion: 'CLSI M100 Ed34 [2024]',
        microhmDBVersion: 'v0.26.3'
    }
};

export {
    kgASTVersions
}