import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloudUrlStatus from './CloudUrlStatus';

import { kgASTVersions } from '../AMRPredictions/kgASTVersionReference';

const styles = {
  container: {
    p: 2,
  },
  metaInfo: {
    mb: 2,
  },
  divider: {
    my: 2,
  },
  timestamp: {
    color: 'text.secondary',
    fontSize: 'small',
    display: 'block',
  }
};

function KeynomeCloudStatusDetails() {
  const kgASTVersion = 'v0.7.7.0';

  return (
    <Box sx={styles.container}>
      <Stack spacing={2}>
        <CloudUrlStatus url={process.env.REACT_APP_KEYNOME_API_URL_BASE}/>
        <CloudUrlStatus url={process.env.REACT_APP_KEYNOME_AUTH0_DOMAIN}/>

        <Box>
          <Typography>
            <Box component="span" fontWeight="bold">Current Stable Release:</Box>{' '}
            v2025.02.24.a
          </Typography>
          <Box sx={{ ml: 2, mt: 1 }}>
            <Typography>
              Keynome ID: v3.0{' '}
              <Typography component="span" sx={styles.timestamp}>
                (MicroRef v2.2.1)
              </Typography>
            </Typography>
            <Typography sx={{ mt: 0.5 }}>
              Keynome gAST: {kgASTVersion}{' '}
              <Typography component="span" sx={styles.timestamp}>
                (MicrohmDB {kgASTVersions[kgASTVersion].microhmDBVersion} / {kgASTVersions[kgASTVersion].clsiVersion})
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Stack>

    </Box>
  );
}

export default KeynomeCloudStatusDetails;
