import React from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { calculateSequencingStats, formatBaseCount } from './utils/sequencingStats';


const styles = {
  lockIcon: {
    padding: '2px',
    marginLeft: '4px',
    color: 'rgba(0, 0, 0, 0.4)',
    marginTop: '-3px',
  },
  indented: {
    marginLeft: '40px'
  },
};


function SequencingYieldStats(props) {
  const {
    analysis,
    microbialMegabases,
    microbialReads,
    sequencingFileData,
    setIsHumanDataDialogOpen
  } = props;

  const isIllumina = analysis.attributes.raw_total_megabases_r2 !== null;
  const {
    totalMegabases,
    downsampledMegabases,
    downsampledReads,
    qcFilteredMegabases,
    qcFilteredReads,
    humanReadsRemovedMegabases,
    humanReadsRemovedReads,
    analyzedMegabases,
    unclassifiedMegabases,
    unclassifiedReads,
  } = calculateSequencingStats(analysis, isIllumina, microbialMegabases, microbialReads);

  const StatRow = ({ label, megabases, reads, color, indent, children }) => (
    <p style={{ color, ...(indent ? styles.indented : {}) }}>
      <b>{label}</b>
      {' '}
      {formatBaseCount(megabases)} ({reads?.toLocaleString() || '-'} read{isIllumina ? ' pairs' : 's'})
      {children}
    </p>
  );

  return (
    <Box sx={{ padding: '35px 40px' }}>
        {sequencingFileData?.attributes.human_dna_deleted_at && (
            <StatRow
            label="Removed as Human:"
            megabases={sequencingFileData.attributes.human_dna_megabases + (sequencingFileData.attributes.human_dna_megabases_r2 || 0)}
            reads={sequencingFileData.attributes.human_dna_reads + (sequencingFileData.attributes.human_dna_reads_r2 || 0)}
            color="#5267AA"
          >
            {(sequencingFileData.attributes.human_dna_megabases > 0 || (sequencingFileData.attributes.human_dna_megabases_r2 || 0) > 0) && (
                <IconButton
                    size="small"
                    onClick={() => setIsHumanDataDialogOpen(true)}
                    sx={styles.lockIcon}
                >
                    <LockIcon fontSize="small" />
                </IconButton>
            )}
            </StatRow>
        )}
        <StatRow label="Input Data:" megabases={totalMegabases} reads={analysis?.attributes.raw_total_reads} />

        <Divider />
        <StatRow label="Removed by Downsampling:" megabases={downsampledMegabases} reads={downsampledReads} color="gray" indent />
        <StatRow label="Removed as Low Quality:" megabases={qcFilteredMegabases} reads={qcFilteredReads} color="#FF5326" indent />
        
        {sequencingFileData && !sequencingFileData.attributes.human_dna_deleted_at && (
            <StatRow label="Removed as Human:" megabases={humanReadsRemovedMegabases} reads={humanReadsRemovedReads} color="#5267AA" indent />
        )}
        <Divider />
        <StatRow label="Analyzed Data:" megabases={analyzedMegabases} reads={analysis?.attributes.human_reads_removed_reads} color="green" />
        <StatRow label="Microbial Data:" megabases={microbialMegabases} reads={microbialReads} color="DarkSeaGreen" indent />
        <StatRow label="Unclassified Data:" megabases={unclassifiedMegabases} reads={unclassifiedReads} color="DarkSeaGreen" indent />
    </Box>
  );
}

export default SequencingYieldStats;
