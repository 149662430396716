/* eslint react/jsx-filename-extension: 0 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { datadogRum  } from '@datadog/browser-rum';

if (process.env.REACT_APP_KEYNOME_ENV === 'prod') {
    datadogRum.init({
        applicationId: '416df8aa-fc25-4bfa-a8ca-5bb1d1710d5d',
        clientToken: 'pubce1426cb44a7e0dbd8fd56176f2e49c4',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us5.datadoghq.com',
        service: 'keynome-cloud-web-app',
        env: process.env.REACT_APP_KEYNOME_ENV || 'dev',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.REACT_APP_KEYNOME_BUILD_VERSION || 'unknown',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
