import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { InputAdornment, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';

import SampleListItem from './SampleListItem'
function SampleSearchAutocomplete(props) {
  const {
    activeProject,
    setSamples,
    datasetsGridRef,
    hasAccessReportViruses
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [searchableSamplesLoading, setSearchableSamplesLoading] = useState(false);
  const [searchableSamples, setSearchableSamples] = useState([]);
  const [searchableSamplesValue, setSearchableSamplesValue] = useState(null);
  const [searchableSamplesInputValue, setSearchableSamplesInputValue] = useState('');

  useEffect(() => {
    if (!activeProject) {
      return () => {};
    }

    if (searchableSamplesInputValue.length < 5) {
      setSearchableSamplesLoading(false);
      setSearchableSamples([]);
      return () => {};
    }

    setSearchableSamplesLoading(true);
    setSearchableSamples([]);

    const controller = new AbortController();
    const { signal } = controller;

    getAccessTokenSilently()
      .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/lib_seqs/search?value=${searchableSamplesInputValue}`,
            {
              method: 'GET',
              headers,
              signal,
            },
          )
        })
        .then((res) => res.json())
        .then((response) => {
          setSearchableSamplesLoading(false);
          setSearchableSamples(response.data);
        })
        .catch((error) => console.error(error));

    return () => controller.abort();
  }, [getAccessTokenSilently, searchableSamplesInputValue, activeProject]);

  useEffect(() => {
    if (!searchableSamplesValue) {
      setSamples(null);
      return;
    }

    const filteredSearchableSamples = searchableSamples.filter(
      (item) => {
        if (!searchableSamplesValue) {
          return false;
        }

        if (searchableSamplesValue.startsWith('Starts with:')) {
          return item.attributes.sample_name.startsWith(searchableSamplesValue.replace('Starts with: ', ''));
        }

        return item.attributes.sample_name === searchableSamplesValue;
      },
    );

    setSamples(filteredSearchableSamples.sort((b, a) => b.id - a.id));
  }, [searchableSamplesValue, searchableSamples, setSamples]);

  useEffect(() => {
    setSamples(null);
    setSearchableSamplesLoading(false);
    setSearchableSamples([]);
    setSearchableSamplesValue(null);
    setSearchableSamplesInputValue('');
  }, [activeProject, setSamples]);

  let sampleSearchOptions = [];
  if ((searchableSamplesInputValue.length >= 5) && !searchableSamplesLoading) {
    const dedupedSortedSearchableSampleNames = [
      ...new Set(
        searchableSamples.sort((a, b) => b.id - a.id),
      ),
    ];

    sampleSearchOptions = dedupedSortedSearchableSampleNames;
    sampleSearchOptions.unshift('SEARCH RESULTS')
  }

  const sampleSearchNoOptionsText = searchableSamplesInputValue.length >= 5
    ? 'No samples found'
    : 'Enter at least 5 characters';

  let autocompleteValue = searchableSamplesValue;

  return (
    <Autocomplete
      disablePortal
      clearOnBlur={false}
      filterOptions={(x) => x.attributes ? x.attributes.sample_name : x}
      id="sample-autocomplete"
      loading={searchableSamplesLoading}
      options={sampleSearchOptions}
      getOptionLabel={(option) => option.attributes ? option.attributes.sample_name : option}
      noOptionsText={sampleSearchNoOptionsText}
      onChange={(_, newValue) => newValue ? setSearchableSamplesValue(newValue) : null}
      onInputChange={(_, newInputValue) => setSearchableSamplesInputValue(newInputValue)}
      sx={{ width: '100%' }}
      value={autocompleteValue}
      renderOption={(props, option) => {
          if (!option.attributes) {
              return (
              <Box key={props.key} component="li" sx={{p: '10px 20px', fontWeight: "bold", fontSize: 12, color: "gray"}}>
                {option}
              </Box>
            )
          }

          return (
              <SampleListItem key={props.key} sample={option} project={activeProject} datasetsGridRef={datasetsGridRef} hasAccessReportViruses={hasAccessReportViruses} />
          )
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => {
        return (
          <TextField {...params} InputProps={{...params.InputProps, startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}} label="Search Samples" />
        )
      }}
    />
  );
}

export default SampleSearchAutocomplete;
