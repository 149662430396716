const KgASTv0_7_5_1PanelReference = [
    {
        id: 1,
        organism: "Acinetobacter calcoaceticus/baumannii complex",
        qualified_antibiotics: ['ciprofloxacin', 'gentamicin', 'imipenem', 'meropenem'],
        rnd_antibiotics: ['amikacin', 'ceftazidime', 'colistin', 'levofloxacin', 'piperacillin/tazobactam', 'tetracycline', 'tobramycin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 2,
        organism: "Citrobacter freundii complex",
        qualified_antibiotics: [],
        rnd_antibiotics: ['tetracycline']
    },
    {
        id: 3,
        organism: "Enterobacter cloacae complex",
        qualified_antibiotics: [],
        rnd_antibiotics: ['ciprofloxacin', 'gentamicin', 'levofloxacin', 'tobramycin']
    },
    {
        id: 4,
        organism: "Enterococcus faecalis",
        qualified_antibiotics: ['ciprofloxacin', 'erythromycin', 'levofloxacin', 'tetracycline'],
        rnd_antibiotics: ['gentamicin high level', 'minocycline', 'streptomycin high level', 'vancomycin']
    },
    {
        id: 5,
        organism: "Enterococcus faecium",
        qualified_antibiotics: ['levofloxacin', 'tetracycline', 'vancomycin'],
        rnd_antibiotics: ['ampicillin', 'ciprofloxacin', 'doxycycline']
    },
    {
        id: 6,
        organism: "Escherichia coli",
        qualified_antibiotics: ['ampicillin', 'cefazolin', 'cefepime', 'cefotaxime', 'ceftriaxone', 'cefuroxime', 'ciprofloxacin', 'gentamicin', 'levofloxacin', 'tetracycline', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['amoxicillin', 'amoxicillin/clavulanate', 'ampicillin/sulbactam', 'aztreonam', 'ceftazidime', 'imipenem', 'tobramycin', 'trimethoprim']
    },
    {
        id: 7,
        organism: "Klebsiella michiganensis",
        qualified_antibiotics: [],
        rnd_antibiotics: ['trimethoprim/sulfamethoxazole']
    },
    {
        id: 8,
        organism: "Klebsiella pneumoniae",
        qualified_antibiotics: ['aztreonam', 'ceftriaxone', 'ertapenem', 'gentamicin', 'imipenem', 'levofloxacin', 'tobramycin'],
        rnd_antibiotics: ['amikacin', 'amoxicillin/clavulanate', 'ampicillin/sulbactam', 'cefazolin', 'cefepime', 'cefotaxime', 'ceftazidime', 'cefuroxime', 'ciprofloxacin', 'doripenem', 'meropenem', 'nalidixic acid', 'tetracycline', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 9,
        organism: "Klebsiella quasipneumoniae",
        qualified_antibiotics: [],
        rnd_antibiotics: ['tobramycin']
    },
    {
        id: 10,
        organism: "Morganella morganii",
        qualified_antibiotics: ['ciprofloxacin', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['levofloxacin', 'tetracycline']
    },
    {
        id: 11,
        organism: "Proteus mirabilis",
        qualified_antibiotics: ['ciprofloxacin', 'levofloxacin'],
        rnd_antibiotics: ['ampicillin', 'ampicillin/sulbactam', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 12,
        organism: "Pseudomonas aeruginosa group",
        qualified_antibiotics: [],
        rnd_antibiotics: ['amikacin', 'ciprofloxacin', 'gentamicin', 'tobramycin']
    },
    {
        id: 13,
        organism: "Serratia marcescens",
        qualified_antibiotics: [],
        rnd_antibiotics: ['tetracycline']
    },
    {
        id: 14,
        organism: "Staphylococcus aureus",
        qualified_antibiotics: ['ciprofloxacin', 'clindamycin', 'erythromycin', 'gentamicin', 'levofloxacin', 'moxifloxacin', 'oxacillin', 'tetracycline'],
        rnd_antibiotics: ['doxycycline', 'rifampicin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 15,
        organism: "Staphylococcus epidermidis",
        qualified_antibiotics: ['ciprofloxacin', 'clindamycin', 'doxycycline', 'erythromycin', 'gentamicin', 'levofloxacin', 'moxifloxacin', 'norfloxacin', 'oxacillin', 'tetracycline'],
        rnd_antibiotics: ['trimethoprim/sulfamethoxazole']
    },
    {
        id: 16,
        organism: "Staphylococcus hominis",
        qualified_antibiotics: ['levofloxacin'],
        rnd_antibiotics: ['ciprofloxacin', 'erythromycin', 'moxifloxacin', 'tetracycline']
    },
    {
        id: 17,
        organism: "Staphylococcus lugdunensis",
        qualified_antibiotics: [],
        rnd_antibiotics: ['erythromycin']
    },
    {
        id: 18,
        organism: "Streptococcus agalactiae",
        qualified_antibiotics: ['clindamycin', 'erythromycin'],
        rnd_antibiotics: ['levofloxacin']
    },
    {
        id: 19,
        organism: "Streptococcus pneumoniae",
        qualified_antibiotics: ['clarithromycin', 'clindamycin', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['cefuroxime', 'doxycycline', 'erythromycin', 'imipenem', 'meropenem', 'tetracycline']
    },
    {
        id: 20,
        organism: "Streptococcus pyogenes",
        qualified_antibiotics: [],
        rnd_antibiotics: ['tetracycline']
    },
];


const KgASTv0_7_6_0PanelReference = [
    {
        id: 1,
        "organism": "Pseudomonas aeruginosa group",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "amikacin",
            "ciprofloxacin",
            "tobramycin",
        ]
    },
    {
        id: 3,
        "organism": "Morganella morganii",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "ciprofloxacin",
            "levofloxacin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 4,
        "organism": "Citrobacter freundii complex",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "tetracycline"
        ]
    },
    {
        id: 5,
        "organism": "Enterobacter cloacae complex",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "cefuroxime",
            "ciprofloxacin",
            "gentamicin",
            "levofloxacin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 6,
        "organism": "Acinetobacter calcoaceticus/baumannii complex",
        "qualified_antibiotics": [
            "amikacin",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "meropenem"
        ],
        "rnd_antibiotics": [
            "cefepime",
            "ceftazidime",
            "colistin",
            "levofloxacin",
            "piperacillin/tazobactam",
            "tetracycline",
            "tobramycin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 7,
        "organism": "Klebsiella pneumoniae",
        "qualified_antibiotics": [
            "levofloxacin",
            "imipenem",
            "gentamicin",
            "ertapenem",
            "ciprofloxacin",
            "ceftriaxone",
            "aztreonam",
            "tobramycin"
        ],
        "rnd_antibiotics": [
            "amikacin",
            "amoxicillin/clavulanate",
            "ampicillin/sulbactam",
            "cefepime",
            "cefotaxime",
            "ceftazidime",
            "cefuroxime",
            "doripenem",
            "meropenem",
            "moxifloxacin",
            "nalidixic acid",
            "tetracycline",
            "ticarcillin/clavulanate",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 8,
        "organism": "Escherichia coli",
        "qualified_antibiotics": [
            "trimethoprim/sulfamethoxazole",
            "tetracycline",
            "levofloxacin",
            "gentamicin",
            "ciprofloxacin",
            "cefuroxime",
            "ceftriaxone",
            "ceftazidime",
            "cefepime",
            "cefazolin",
            "aztreonam",
            "ampicillin",
        ],
        "rnd_antibiotics": [
            "amoxicillin",
            "ampicillin/sulbactam",
            "cefotaxime",
            "ertapenem",
            "imipenem",
            "tobramycin",
        ]
    },
    {
        id: 9,
        "organism": "Enterococcus faecium",
        "qualified_antibiotics": [
            "vancomycin",
            "tetracycline",
            "levofloxacin"
        ],
        "rnd_antibiotics": [
            "ampicillin",
            "ciprofloxacin",
            "daptomycin",
            "doxycycline",
        ]
    },
    {
        id: 10,
        "organism": "Proteus mirabilis",
        "qualified_antibiotics": [
            "ciprofloxacin",
            "levofloxacin"
        ],
        "rnd_antibiotics": [
            "ampicillin",
            "ampicillin/sulbactam",
            "gentamicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 11,
        "organism": "Staphylococcus aureus",
        "qualified_antibiotics": [
            "ciprofloxacin",
            "tetracycline",
            "oxacillin",
            "moxifloxacin",
            "levofloxacin",
            "gentamicin",
            "erythromycin",
            "clindamycin",
        ],
        "rnd_antibiotics": [
            "cefoxitin",
            "doxycycline",
            "methicillin",
            "rifampicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 12,
        "organism": "Streptococcus pneumoniae",
        "qualified_antibiotics": [
            "erythromycin",
            "clarithromycin",
            "trimethoprim/sulfamethoxazole"
        ],
        "rnd_antibiotics": [
            "cefuroxime",
            "clindamycin",
            "doxycycline",
            "meropenem",
            "tetracycline",
        ]
    },
    {
        id: 13,
        "organism": "Streptococcus agalactiae",
        "qualified_antibiotics": [
            "erythromycin",
            "clindamycin",
        ],
        "rnd_antibiotics": []
    },
    {
        id: 14,
        "organism": "Staphylococcus hominis",
        "qualified_antibiotics": [
            "ciprofloxacin"
        ],
        "rnd_antibiotics": [
            "erythromycin",
            "moxifloxacin",
            "tetracycline",
        ]
    },
    {
        id: 15,
        "organism": "Staphylococcus epidermidis",
        "qualified_antibiotics": [
            "tetracycline",
            "oxacillin",
            "moxifloxacin",
            "levofloxacin",
            "gentamicin",
            "erythromycin",
            "doxycycline",
            "clindamycin",
            "ciprofloxacin",
        ],
        "rnd_antibiotics": [
            "rifampicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 16,
        "organism": "Enterococcus faecalis",
        "qualified_antibiotics": [
            "tetracycline",
            "erythromycin",
            "gentamicin high level",
            "levofloxacin",
            "ciprofloxacin"
        ],
        "rnd_antibiotics": [
            "minocycline",
            "vancomycin",
        ]
    },
    {
        id: 17,
        "organism": "Staphylococcus lugdunensis",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "erythromycin",
        ]
    }
];

const KgASTv0_7_7_0PanelReference = [
    {
        id: 1,
        organism: "Acinetobacter calcoaceticus/baumannii complex",
        qualified_antibiotics: ['amikacin', 'ciprofloxacin', 'gentamicin', 'imipenem', 'levofloxacin', 'meropenem', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['ampicillin/sulbactam', 'cefepime', 'ceftazidime', 'colistin', 'doripenem', 'minocycline', 'piperacillin/tazobactam', 'tetracycline', 'tobramycin']
    },
    {
        id: 2,
        organism: "Citrobacter freundii complex",
        qualified_antibiotics: [],
        rnd_antibiotics: ['trimethoprim/sulfamethoxazole']
    },
    {
        id: 3,
        organism: "Enterobacter cloacae complex",
        qualified_antibiotics: [],
        rnd_antibiotics: ['amikacin', 'cefuroxime', 'ciprofloxacin', 'gentamicin', 'levofloxacin', 'meropenem', 'tobramycin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 4,
        organism: "Enterococcus faecalis",
        qualified_antibiotics: ['ciprofloxacin', 'erythromycin', 'gentamicin high level', 'levofloxacin', 'tetracycline'],
        rnd_antibiotics: ['minocycline', 'streptomycin high level', 'vancomycin']
    },
    {
        id: 5,
        organism: "Enterococcus faecium",
        qualified_antibiotics: ['tetracycline', 'vancomycin'],
        rnd_antibiotics: ['ciprofloxacin', 'doxycycline', 'levofloxacin']
    },
    {
        id: 6,
        organism: "Escherichia coli",
        qualified_antibiotics: ['ampicillin', 'aztreonam', 'cefazolin', 'ceftriaxone', 'cefuroxime', 'ciprofloxacin', 'gentamicin', 'levofloxacin', 'tetracycline', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['amikacin', 'amoxicillin/clavulanate', 'ampicillin/sulbactam', 'cefepime', 'ceftazidime', 'ertapenem', 'imipenem', 'tobramycin']
    },
    {
        id: 7,
        organism: "Klebsiella pneumoniae",
        qualified_antibiotics: ['amikacin', 'ampicillin/sulbactam', 'ceftriaxone', 'cefuroxime', 'ciprofloxacin', 'ertapenem', 'gentamicin', 'imipenem', 'levofloxacin', 'tobramycin'],
        rnd_antibiotics: ['amoxicillin/clavulanate', 'aztreonam', 'cefazolin', 'cefotaxime', 'ceftazidime', 'doripenem', 'meropenem', 'moxifloxacin', 'nalidixic acid', 'tetracycline', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 8,
        organism: "Morganella morganii",
        qualified_antibiotics: ['ciprofloxacin'],
        rnd_antibiotics: ['levofloxacin', 'tetracycline', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 9,
        organism: "Proteus mirabilis",
        qualified_antibiotics: ['ciprofloxacin', 'levofloxacin'],
        rnd_antibiotics: ['ampicillin', 'ampicillin/sulbactam', 'gentamicin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 10,
        organism: "Pseudomonas aeruginosa group",
        qualified_antibiotics: [],
        rnd_antibiotics: ['ciprofloxacin', 'gentamicin', 'tobramycin']
    },
    {
        id: 11,
        organism: "Staphylococcus aureus",
        qualified_antibiotics: ['ciprofloxacin', 'clindamycin', 'doxycycline', 'erythromycin', 'levofloxacin', 'moxifloxacin', 'oxacillin', 'tetracycline'],
        rnd_antibiotics: ['gentamicin', 'rifampicin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 12,
        organism: "Staphylococcus epidermidis",
        qualified_antibiotics: ['ciprofloxacin', 'doxycycline', 'erythromycin', 'gentamicin', 'levofloxacin', 'moxifloxacin', 'oxacillin', 'tetracycline'],
        rnd_antibiotics: ['clindamycin', 'rifampicin', 'trimethoprim/sulfamethoxazole']
    },
    {
        id: 13,
        organism: "Staphylococcus hominis",
        qualified_antibiotics: [],
        rnd_antibiotics: ['ciprofloxacin', 'clindamycin', 'erythromycin', 'tetracycline']
    },
    {
        id: 14,
        organism: "Staphylococcus lugdunensis",
        qualified_antibiotics: [],
        rnd_antibiotics: ['clindamycin', 'erythromycin']
    },
    {
        id: 15,
        organism: "Streptococcus agalactiae",
        qualified_antibiotics: ['clindamycin', 'erythromycin'],
        rnd_antibiotics: []
    },
    {
        id: 16,
        organism: "Streptococcus pneumoniae",
        qualified_antibiotics: ['clarithromycin', 'erythromycin', 'tetracycline', 'trimethoprim/sulfamethoxazole'],
        rnd_antibiotics: ['azithromycin', 'cefuroxime', 'clindamycin', 'doxycycline', 'meropenem']
    },
];

const modelPanelReference = {
    "v0.7.5.1": KgASTv0_7_5_1PanelReference,
    "v0.7.6.0": KgASTv0_7_6_0PanelReference,
    "v0.7.7.0": KgASTv0_7_7_0PanelReference
}

export default modelPanelReference;
