function aggregateKgASTQualityLimitations(isIllumina, identifiedSpeciesAttributes, q20Rate, assemblyN50, assemblyCoverageLT2xDepth) {
    let qualityLimitations = []
  
    // Q20 rate is a sequencing quality metric and is not avialable at the per identified species level
    if (q20Rate <= 0.70) {
      qualityLimitations.push('low quality sequencing data')
    }
  
    if (identifiedSpeciesAttributes.assembly_frac_agreeing_mbs <= 0.75) {
      qualityLimitations.push('possible data contamination')
    }
  
    if (isIllumina) {
      // Not all attributes are available for Illumina, so can return early
      return qualityLimitations;
    }
  
    // Assembly N50 and Coverage <2x depth is available per identified species for more recently processed samples but
    // for older analyses, may have to fallback to assembly N50 prior to taxonomic binning
    if (
      (((identifiedSpeciesAttributes.assembly_n50 || assemblyN50) <= 6000) ||
        ((1 - (identifiedSpeciesAttributes.assembly_coverage_lt_2x_depth || assemblyCoverageLT2xDepth)) <= 0.95))
    ) {
      qualityLimitations.push('low quality de novo assembly')
    }
  
    // KID value (raw value from identified species call) is always available at the identified species
    // level
    if (
      (identifiedSpeciesAttributes.species_call_raw_value <= 0.9)
    ) {
      qualityLimitations.push('insufficient genomic recovery')
    }
  
    return qualityLimitations
  }

export default aggregateKgASTQualityLimitations;