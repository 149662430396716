// KeynomeManagerDownload.jsx
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import DownloadListItem from './DownloadListItem';
import KeynomeManagerDownloadInstructions from './KeynomeManagerDownloadInstructions.jsx';


const styles = {
  profileHeader: {
    backgroundColor: '#eee',
    padding: '1px 0px 1px 10px'
  },
  apiKeysList: {
    width: '100%',
    bgcolor: 'background.paper',
    paddingTop: '0',
    paddingBottom: '0'
  },
};

function KeynomeManagerDownload() {
  return (
    <>
      <Box sx={styles.profileHeader}>
        <p><b>Download Keynome Manager</b></p>
      </Box>
      <Box>
        <List sx={styles.apiKeysList}>
          <DownloadListItem platform="windows" />
          <DownloadListItem platform="linux" />
          <DownloadListItem platform="macos_intel" />
          <DownloadListItem platform="macos_arm64" />
        </List>
      </Box>
      <KeynomeManagerDownloadInstructions />
    </>
  );
}

export default KeynomeManagerDownload;
