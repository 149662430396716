import { Graph, alg } from 'graphlib';
import IntervalTree from '@flatten-js/interval-tree'

/**
 * Determines if two intervals have an overlap greater than a given threshold.
 *
 * @param {number} start1 - The start index of the first interval.
 * @param {number} stop1 - The stop index of the first interval.
 * @param {number} start2 - The start index of the second interval.
 * @param {number} stop2 - The stop index of the second interval.
 * @param {number} threshold - The overlap threshold (e.g., 0.8 for 80%).
 * 
 * @returns {boolean} True if the intervals overlap by more than the threshold, false otherwise.
 */
function hasSignificantOverlap(start1, stop1, start2, stop2, threshold) {
  const intersection = Math.max(0, Math.min(stop1, stop2) - Math.max(start1, start2));
  const length1 = stop1 - start1;
  const length2 = stop2 - start2;
  return (length1 > 0 && intersection / length1 > threshold) ||
         (length2 > 0 && intersection / length2 > threshold);
}

/**
 * Identifies overlapping resistance genes where each gene overlaps
 * with at least one other gene by more than the provided threshold.
 * 
 * Uses an interval tree to avoid comparing resistance genes that do not overlap.
 *
 * @param {Array} genes - An array of gene objects, each with keys { id, contig_id, start, stop }.
 * @param {number} [threshold=0.8] - The overlap threshold (e.g., 0.8 for 80%).
 * @returns {Array} An array of clusters; each cluster is an array of gene ids.
 */
export function identifyOverlappingResistanceGenes(genes, threshold = 0.8) {
  const graph = new Graph({ directed: false });
  genes.forEach(({ id }) => graph.setNode(id));

  const tree = new IntervalTree();
  genes.forEach(({ id, contig_id, start, stop }) => {
    tree.insert([start, stop], { id, contig_id, start, stop });
  });

  const initialAcc = { processedPairs: new Set(), graph };
  genes.reduce((acc, { id, contig_id, strand, start, stop }) => {
    const candidates = tree.search([start, stop]);
    candidates.forEach(({ id: candidateId, contig_id: candidateContigID, strand: candidateStrand, start: candidateStart, stop: candidateStop }) => {
      if ((id === candidateId) || (contig_id !== candidateContigID) || (strand !== candidateStrand)) return;

      const pairKey = id < candidateId ? `${id}-${candidateId}` : `${candidateId}-${id}`;
      if (acc.processedPairs.has(pairKey)) return;

      acc.processedPairs.add(pairKey);

      if (hasSignificantOverlap(start, stop, candidateStart, candidateStop, threshold)) {
        acc.graph.setEdge(id, candidateId);
      }
    });
    return acc;
  }, initialAcc);

  return alg.components(graph);
}