import React, {useState, useEffect, useCallback} from 'react';
import { Box, Grid, Divider, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { datadogRum  } from '@datadog/browser-rum';

import IdentifiedSpeciesVersionDialog from '../Runs/IdentifiedSpeciesVersionDialog'

import { removePrefixFromAnalysisVersion } from '../Analyses/canonicalizeMetadata';

function DetailedSampleMetadataSummary(props) {
  const navigate = useNavigate();
  const {
    sample,
    analysis,
    activeProject
  } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const [archiveDataset, setArchiveDataset] = useState(null);
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const handleArchiveClick = () => {
    setArchiveDialogOpen(true);
  };
  
const fetchArchiveProject = async (headers, organizationId) => {
  const res = await fetch(
    `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organizationId}/projects`,
    { method: 'GET', headers }
  );
  
  if (!res.ok) {
    throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
  }
  
  const data = await res.json();
  const archiveProject = data.data.find(project => project.attributes.name === "archive");
  return archiveProject;
};

const fetchArchiveDataset = async (headers, projectId) => {
  const res = await fetch(
    `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${projectId}/sequencing_runs`,
    { method: 'GET', headers }
  );

  if (!res.ok) {
    throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
  }

  const data = await res.json();
  const archiveRun = data.data.find(run => run.attributes.name === "archive");
  return archiveRun;
};

const moveLibraryToDataset = async (headers, sampleId, datasetID) => {
  const res = await fetch(
    `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sampleId}`,
    {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: "lib_seq",
          id: sampleId,
          attributes: { run_id: datasetID }
        }
      })
    }
  );

  if (!res.ok) {
    throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
  }
  return res.json();
};

const handleMenuClick = (event) => {
  setMenuAnchorEl(event.currentTarget);
};

const handleMenuClose = () => {
  setMenuAnchorEl(null);
};

useEffect(() => {
  if (!activeProject) {
    return;
  }

  const loadArchiveProject = async () => {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const archiveProject = await fetchArchiveProject(headers, activeProject?.attributes?.organization_id);
      const archiveDataset = archiveProject ? await fetchArchiveDataset(headers, archiveProject.id) : null;
      setArchiveDataset(archiveDataset)
 }

 loadArchiveProject();
}, [activeProject, getAccessTokenSilently]);


const handleArchiveConfirm = useCallback(() => {
  const archiveLibrary = async () => {
     try {
       setIsArchiving(true);
       const accessToken = await getAccessTokenSilently();
       const headers = { Authorization: `Bearer ${accessToken}` };
       await moveLibraryToDataset(headers, sample.id, archiveDataset.id);
       setArchiveDialogOpen(false);
       navigate('/');
     } catch (error) {
        datadogRum.addError(error);
        alert(`Failed to archive sample: ${error.message}`);
      } finally {
        setIsArchiving(false);
      }
    }

 archiveLibrary();
}, [archiveDataset, sample, getAccessTokenSilently, navigate]);

  const handleArchiveCancel = () => {
    setArchiveDialogOpen(false);
  };
  let analysisExecutionTimeString = null;
    if (analysis) {
        const diffMs = new Date(analysis.attributes.updated_at) - new Date(analysis.attributes.created_at)
        const diffMins = Math.round(diffMs / 60000);
        analysisExecutionTimeString = `(${diffMins} min.)`
    }
  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={6}>
            <p><b>SAMPLE / ANALYSIS</b></p>
          </Grid>
          {archiveDataset ? (
            <Grid item xs={6} sx={{textAlign: 'right', paddingTop: "5px"}}>
              <IconButton
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => {
                  handleArchiveClick();
                  handleMenuClose();
                }}>
                  Archive Sample
                </MenuItem>
              </Menu>
            </Grid>
          ): null}
          </Grid>
      </Box>
      <Dialog
        open={archiveDialogOpen}
        onClose={handleArchiveCancel}
      >
        <DialogTitle>Archive Sample</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this sample? After archiving, you will no longer be able to see any results associated with this sample.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArchiveCancel} disabled={isArchiving}>Cancel</Button>
          <Button 
            onClick={handleArchiveConfirm}
            color="primary"
            variant="contained"
            autoFocus
            disabled={isArchiving}
          >
            {isArchiving ? 'Archiving...' : 'Yes, Archive'}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Sample Name:</b>
          {' '}
          {sample?.attributes.sample_name || sample?.attributes.sample_id || sample?.attributes.library_id || 'N/A'}
        </p>
        <p>
          <b>Sample Type:</b>
          {' '}
          {sample?.attributes.sample_type || 'Unknown'}
        </p>
        <div>
            <Divider />
            &nbsp;
        </div>
        <p><b>Analysis Version</b>: {analysis?.attributes.metadata.minion_pipeline_version ? (<Link onClick={() => setVersionDialogOpen(true)}sx={{'&:hover': {color: 'orange', cursor: 'pointer', textDecoration: 'underline'}}}>{removePrefixFromAnalysisVersion(analysis.attributes.metadata.minion_pipeline_version)}</Link>) : '-'}</p>
        <p><b>Analysis Started At</b>: {analysis ? new Date(analysis.attributes.created_at).toLocaleString() : null}</p>
        <p><b>Analysis Completed At</b>: {analysis ? new Date(analysis.attributes.updated_at).toLocaleString() : null} <span style={{color: 'gray'}}>{analysisExecutionTimeString}</span></p>
      </Box>
      <IdentifiedSpeciesVersionDialog open={versionDialogOpen} setOpen={setVersionDialogOpen} analysis={analysis} />
    </>
  );
}

export default DetailedSampleMetadataSummary;
